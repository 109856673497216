import React, { useContext, useState } from 'react';
export const SidebarContext = React.createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export function SidebarProvider({ value, children }) {
    const [stateSidebar, setStateSidebar] = useState("halfopen");

  
   
    function toogleSidebar() {
        if (stateSidebar === "halfopen") {
            setStateSidebar("halfclose")
        } else {
            setStateSidebar("halfopen")
        }
    }



    return <SidebarContext.Provider value={{toogleSidebar, stateSidebar}}>
        {children}
    </SidebarContext.Provider>
}