import React from 'react'
import { useThemeContext } from '../../context/themeContext'
import "./styles.css"


export default function ToogleTheme({theme}) {
    const { toogleTheme } = useThemeContext();
    const local = theme === "light" ? true : theme === "dark"?false:null

    return (
        <div>
            <label className="switch">
                <input type="checkbox" onClick={() => toogleTheme()} defaultChecked={local} />
                <span className="slider round"></span>
            </label>
        </div>
    )
}
