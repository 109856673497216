
function parseoPie(dato, factor) {
   try {

      var nuevoDato = dato.map((x) => {
         return { "id": x.id, "label": x.id, "value": x.value / factor }
      })
      return nuevoDato
   }
   catch (err) {
      console.log(err)
      return []
   }
}

function parseoMachinePie(dato, factor) {
    try {
 
       var nuevoDato = dato.map((x) => {
          return { "id": x.metadata, "label": x.metadata, "value": x.value / factor }
       })
       return nuevoDato
    }
    catch (err) {
       console.log(err)
       return []
    }
 }


function parseoTableLabels(dato){
   var newArray = []
   dato.forEach(machine => {
       let labelsItems = machine.devices.filter(device => device.type === "Labels")
       labelsItems.forEach(elements => {
           let nuevo = elements.attributes.filter(device => device.name === "Labels")
           nuevo.forEach(element => {
               newArray.push({ "service": machine.services.name, "name": machine.name, "store": machine.store.name, "value": element.value })
               // console.log(element.value)
           })
       })
   })
   return newArray.sort((a,b)=>a.value-b.value)
}

function parseoTableDispensers(dato){
   var newArray = []
//    console.log(dato);
   dato.forEach(machine => {
       let labelsItems = machine.devices.filter(device => device.type === "Dispenser")
       labelsItems.forEach(elements => {
        //    console.log(elements);
           let nuevo = elements.attributes.filter(device => device.name === "remaining")
           nuevo.forEach(element => {
               newArray.push({ "service": machine.services.name, "name": machine.name, "store": machine.store.name, "dispositivo":elements.pretty_name,"value": element.value/1000 })
           })
       })
   })
   return newArray.sort((a,b)=>a.value-b.value)
}

function parseoMachines(dato) {
    // console.log(dato);
   let final = dato.map((machine) => {
       let remainingData = []
       let labelsData = []
       machine.devices.forEach((device) => {
           device.attributes.filter(e => e.name === "remaining").forEach((e) => {
               remainingData.push({ "name": device.pretty_name, "value": e.value })
           })
           device.attributes.filter(e => e.name === "Labels").forEach((e) => {
               labelsData = e.value
           })
       })
       return { "name": machine.pretty_name, "id": machine.machine_name, "sucursal": machine.store.name, "remaining": remainingData, "labels": labelsData, "location": machine.location, "service": machine.services.name}
   })
   return final
}

async function isIOS() {
   var userAgent = window.navigator.userAgent;
   console.log(userAgent)
   return (/iP(hone|od|ad)/.test(userAgent))
 }
   export { parseoPie, parseoTableLabels, parseoTableDispensers,parseoMachines, parseoMachinePie,isIOS }