import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {getToken} from "./../../services/Services";
import { useServiceContext } from '../../context/serviceContext'


import "./styles.css"

export default function Login({logger, setLogger}) {
    let history = useHistory();

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState("");

    const {initServices } = useServiceContext()


useEffect(() => {
    if (logger === true) {
        history.push("/")
    }
}, [history, logger])



function iniciarSesion() {
   
    getToken(user, pass)
        .then(
            response => {
             
                    localStorage.setItem("token", response.token)
                    localStorage.setItem("user", user)
                    setLogger(true)
                    initServices(user, response.token)
                
            }
        ).catch(error => setError( error ))
}

function handleKeyPress (event) {
    if (event.key === 'Enter') {
        iniciarSesion()
    }
}


    return (
            <div className="loginpage">
                <div className="inner-bg">
                    <div className="" style={{ width: "365px" }}>

                        <div>
                            <div className=" form-box">
                                <div className="form-top">
                                    <div className="form-top-left">
                                    <h2 className={`text-danger fw-bold`}>
                DisPro</h2>
                                        <p>Ingrese sus datos</p>
                                    </div>
                                    <div className="form-top-right">
                                        <i className="fas fa-user-lock"></i>
                                    </div>
                                </div>
                                <div className="form-bottom">
                                    <div className="login-form">
                                        <div className="form-group">
                                            <label className="sr-only" forhtml="form-username">user</label>
                                            <input  type="email" value={user}  onChange={(e) => {setUser(e.target.value); setError("")}} name="form-username" placeholder="Usuario..." className="form-username form-control" id="form-username" />
                                        </div>
                                        <div className="form-group">
                                            <label className="sr-only" forhtml="form-password">Contraseña</label>
                                            <input  value={pass} onKeyPress={e=>handleKeyPress(e)} onChange={(e) => {setPass(e.target.value); setError("")}} type="password" name="form-password" placeholder="Contraseña..." className="form-password form-control" id="form-password" />
                                        </div>
                                        <div className="text-white mb-2 small bg-danger text-center">{error}</div>
                                        <button type="button" className="bg-info btnlogin" onClick={()=>iniciarSesion()}>Login!</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
               <div className="w-100 text-center position-absolute bottom-0 mb-3"> <img alt="logo-accsa" src="./accsa.png" style={{width: "7rem"}} ></img> </div> 
        </div>
    )
}
