import React, { useEffect, useState } from 'react';
import rutas from './../../routes';
import { getSucursales } from '../../services/Services'
import { useHistory, useLocation } from "react-router-dom";
import SideNav, {
    NavItem,
    NavIcon,
    NavText
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import "./styles.scss"


export default function Sidebar({ theme, services, stateSidebar }) {
    let history = useHistory();
    let location = useLocation();
    const [sucursales, setSucursales] = useState([])

    useEffect(() => {
        if (services.length === 1) {
            getSucursales(localStorage.getItem("user"), localStorage.getItem("token"), services).then(res => setSucursales(res))
        }
    }, [services]);

    function armarRutas() {
        const navegacion = rutas.map((ruta, index) => {
            if (ruta.path === "/location") {
                if (services.length === 1) {
                    return (
                        <NavItem eventKey={ruta.path} key={ruta.path}>
                            <NavIcon>
                                <i className={ruta.icon} style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                {ruta.text}
                            </NavText>
                            {
                                sucursales.map(subruta =>
                                    <NavItem key={"/location/" + subruta.name + "?pn=" + subruta.pretty_name} eventKey={"/location/" + subruta.name + "?pn=" + subruta.pretty_name}>
                                        <NavText >
                                            {subruta.pretty_name}
                                        </NavText>
                                    </NavItem>
                                )
                            }
                        </NavItem>
                    )
                }
            } else {
                return (
                    <NavItem eventKey={ruta.path} key={ruta.path}>
                        <NavIcon>
                            <i className={ruta.icon} style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            {ruta.text}
                        </NavText>
                    </NavItem>
                )
            }
        })
        return navegacion
    }

    return (
        <SideNav
            className={`sidebar sidebar-${theme} sidebar-${stateSidebar}`}
            onSelect={(selected) => {
                if (location.pathname !== selected) {
                    history.push(selected);
                }
            }}
            onToggle={(expanded) => {
                // Add your code here
            }}
        >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected="/">
                {armarRutas()}
            </SideNav.Nav>
        </SideNav>
    )
}
