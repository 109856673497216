import React from 'react'

export default function Alarms() {

    async function showNotification() {
        //Obtener la registración del service worker
        console.log("prueba");
        const registration = await navigator.serviceWorker.getRegistration()
        console.log(registration);
        if (!registration) {
          return console.error("REGISTRATION VACIO");
        } else {
          registration.showNotification("Esto es una alerta de prueba", {
            body: 'Aqui va el contenido',
            vibrate: [300, 100, 400],
            img: '/favicon.png ',
            data: {doge: {wow: 'Datos importantes de la notificacion desde la pagina de prueba'}}
          })
        }
      }
    return (
        <div>
            <h3>Proximamente</h3>
            <button onClick={()=>showNotification()} type="button" className="mt-5 btn btn-danger btn-sm">Probar</button>
        </div>
    )
}
