import React, { useContext, useState, useEffect } from 'react';
import { getServices } from "../services/Services"
export const serviceContext = React.createContext();

export const useServiceContext = () => useContext(serviceContext);

export function ServiceProvider({ value, children }) {
    const [services, setServices] = useState([] || []);
    const [originServices, setOriginServices] = useState([] || []);

    useEffect(() => {
        let user = localStorage.getItem("user")
        let token = localStorage.getItem("token")
        let localService = localStorage.getItem("service")

        if (user && token) {
            getServices(localStorage.getItem("user"), localStorage.getItem("token"))
                .then(response => {
                    let servicios = response.map((s) => {
                        return s.name
                    })
                    setOriginServices(servicios)
                    if (servicios.length === 1) {
                        localStorage.setItem("service", servicios)
                        setServices(servicios)
                    } else if (servicios.length > 1) {
                        if (!servicios.includes(localService)) {
                            localStorage.removeItem("service")
                            setServices(servicios)
                        } else {
                            setServices([localService])
                        }
                    }
                })
        }

    }, [])

    function initServices(user, token) {
        getServices(user, token)
            .then(response => {
                let servicios = response.map((s) => {
                    return s.name
                })
                setOriginServices(servicios)
                if (servicios.length === 1) {
                    localStorage.setItem("service", servicios)
                    setServices(servicios)
                } else if (servicios.length > 1) {
                    setServices(servicios)
                    localStorage.removeItem("service")
                }
            })
    }

    function toogleServices(service) {
        if (service === "clear") {
            setServices(originServices)
            localStorage.removeItem("service")
        } else {
            setServices(service)
            localStorage.setItem("service", service)
        }
    }



    return <serviceContext.Provider value={{ services, toogleServices, initServices, originServices }}>
        {children}
    </serviceContext.Provider>
}