import config from '../config'

//Pide TOKEN con user and pass ingresados por el cliente
async function getToken(username, password,) {
  if (username !== null && password !== null && username !== "" && password !== "") {
    var body = {
      "username": username,
      "password": password,
    };
    return fetch(config.url + ":" + config.port + "/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }).then(response => response.json()).catch(error => Promise.reject("Usuario o contraseña incorrectos"))
  } else {
    return Promise.reject("Debe completar todos los campos")
  }
}

//Trae los service que tiene acceso el user con el token
async function getServices(username, token) {
  return fetch(config.url + ":" + config.port + "/services", {
    method: "GET",
    headers: {
      "username": username,
      "Authorization": token,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getSucursales(username, token, service) {
  return fetch(config.url + ":" + config.port + "/stores", {
    // return fetch(config.url +"/stores", {
    method: "GET",
    headers: {
      "username": username,
      "Authorization": token,
      "service": service,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getAllDevices(service,store, machine) {
  return fetch(config.url + ":" + config.port + "/machines", {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store": store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getCountDevices(service, store) {
  return fetch(config.url + ":" + config.port + "/apis/v1/devices", {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getCountAlarms(service, store) {
  return fetch(config.url + ":" + config.port + "/apis/v1/alarms", {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}
async function getLiterDispensed(from, to, timezone, service, store, machine) {
  return fetch(config.url + ":" +config.port + "/apis/v1/dispensed2?from="+from+"&to="+to+"&offset="+timezone, {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getBillingDispensed(from, to, timezone, service, store, machine) {
  return fetch(config.url + ":" + config.port + "/apis/v1/billing2?from="+from+"&to="+to+"&offset="+timezone, {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getHistoricsTrend(from, to, service, attribute, store, machine) {
  return fetch(config.url + ":" + config.port + "/apis/v1/trend2?from="+from+"&to="+to+"&attribute="+attribute, {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

//Trae los historicos en el formato de graficas de barras
async function getHistoricsBar(from, to, service, attribute, store, machine) {
  return fetch(config.url + ":" + config.port +  "/apis/v1/graphs2?from="+from+"&to="+to+"&attribute="+attribute, {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

//   async function getMachinesLight(service, store) {
//       return fetch(config.url + ":" + config.port +  "/apis/v1/machines", {
//       method: "GET",
//       headers: {
//         "username": localStorage.getItem("user"),
//         "Authorization": localStorage.getItem("token"),
//         "service": service,
//         "store":store,
//         "Content-Type": "application/json",
//       },
//     }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
// }

async function getAllNotifier() {
  return fetch(`http://aconline.com.uy:80/api/notifier/rules`, {
      headers: {
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImRldm90byIsInNlcnZpY2UiOiJkZXZvdG8iLCJ0aW1lc3RhbXAiOjE2MzQxNDQ5MTAyNTEsInJvbCI6MSwiaWF0IjoxNjM0MTQ0OTEwfQ.An3yCTB8s5_ihp9-3I9Bhug_0rKSVBYVVxjbhiZxJl8`,
        'service': `${localStorage.getItem('service')}`,
        'user': `${localStorage.getItem('user')}`
      },
  })
      .then(response => {
          if (response.status === 200 || response.status === 304)
              return response.json()
          else
              return Promise.reject("Too many failed connections to server")
      })
}

async function getFullData(from, to, service, store, machine) {
  return fetch(config.url + ":" + config.port +  "/apis/v1/excel?from="+from+"&to="+to+"&attribute=", {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

async function getDetailTable(from, to, service, store, machine) {
  return fetch(config.url + ":" + config.port +  "/apis/v1/product?from="+from+"&to="+to+"&attribute=", {
    method: "GET",
    headers: {
      "username": localStorage.getItem("user"),
      "Authorization": localStorage.getItem("token"),
      "service": service,
      "store":store,
      "machine":machine,
      "Content-Type": "application/json",
    },
  }).then(response => response.json()).catch(error => Promise.reject("A ocurrido un error"))
}

export {getDetailTable,getFullData,getToken, getServices, getSucursales, getAllDevices, getCountDevices, getCountAlarms, getLiterDispensed, getBillingDispensed, getHistoricsTrend, getHistoricsBar,  getAllNotifier}