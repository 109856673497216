import React from 'react'
import "./styles.css"

export default function PageNotFound() {
    return (
        <div >
                        <div class="container">
                            <h1>
                                404
                            </h1>
                            <h4  >
                            ¡Opps! Página no encontrada
                            </h4>
                            <p>
                            Lo sentimos, la página que estás buscando no existe. Si cree que algo está roto, informe un problema.
                            </p>
                            {/* <div class="btns">
                                <a href="https://dispensadores.aconline.com.uy/">volver a home</a>
                                <a href="https://dispensadores.aconline.com.uy/">reportar</a>
                            </div> */}
                        </div>
                    </div>

    )
}
