import React from 'react'
import ToogleTheme from '../../components/toggleTheme/ToogleTheme'
import config from '../../config';
import {isIOS} from "../../utils/utils"
import './styles.css';
export default function Header({ theme, setLogger, services, toogleServices }) {

    async function cerrarSesion() {
        if (await isIOS()) {
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            setLogger(false)
            toogleServices("clear")
        } else {
            await navigator.serviceWorker.ready.then(async function (reg) {
                await reg.pushManager.getSubscription().then(async function (subscription) {
                    console.log(subscription);
                    await subscription.unsubscribe().then(async function (successful) {
                        console.log(successful);
                        console.log("username " + localStorage.getItem("user"));
                        console.log("token " + localStorage.getItem("token"));
                        await fetch(config.url + ":" + config.port + "/unsubscribe", {
                            method: "POST",
                            body: JSON.stringify(subscription),
                            headers: {
                                "username": localStorage.getItem("user"),
                                "Authorization": localStorage.getItem("token"),
                                "content-type": "application/json"
                            }
                        }).then(res => console.log(res))
                    }).catch(function (e) {
                        // Unsubscription failed
                    })
                })
            }).then(resp => {
                console.log("borrando base localstorage");
                localStorage.removeItem("token")
                localStorage.removeItem("user")
                setLogger(false)
                toogleServices("clear")
            });
        }
    }

    return (
        <div className={`header header-${theme}`}>
            <div className="user-header"><img className="img-fluid" alt="user" src={`../../img/user/${localStorage.getItem("user")}.png`} onError={(e) => { e.target.src = "../../img/user/default.png" }} style={{ width: "100%", borderRadius: "50%" }}></img></div>
            {/* <div className="logoTitulo"><img className="img-fluid" alt="logo" src={logo} /></div> */}

            {/* <div className={`logoTitulo logoTitulo-${theme}`}>DisPro</div> */}
            <div className={`logoTitulo logoTitulo-${theme}`}>
                DisPro {services.length === 1 ?
                    <img style={{ width: "5rem" }} alt={services} src={`../../img/service/${services}${theme === "dark" ? "-" + theme : ""}.png`} onError={(e) => { e.target.alt = "" }} />
                    : null}
            </div>
            <div className="right-box-header">
                <ToogleTheme theme={theme}></ToogleTheme>
                <i className="far fa-bell"></i>
                <i className="fas fa-power-off cursor-pointer" style={{ cursor: "pointer" }} onClick={() => cerrarSesion()}></i>
            </div>
        </div>
    )
}
