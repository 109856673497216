import React, { useContext, useState, useEffect } from 'react';

export const themeContext = React.createContext();

export const useThemeContext = () => useContext(themeContext);

export function ThemeProvider({ value, children }) {
  const [theme, setTheme] = useState("" || []);


  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme("dark")
      localStorage.setItem('Theme', "dark")
    } else {
      setTheme("light")
      localStorage.setItem('Theme', "light")
    };

    // const localTheme = localStorage.getItem('Theme')
    // if (!localTheme) {
    //   console.log("n el primero");
    //   setTheme("light")
    //   localStorage.setItem('Theme', "light")
    // }
    // if (localTheme === "light") {
    //   setTheme("light")

    // }
    // if (localTheme === "dark") {
    //   setTheme("dark")

    // }

    // console.log(window.matchMedia('(prefers-color-scheme: dark)').matches);
    // window.matchMedia('(prefers-color-scheme: dark)')
    //   .addEventListener('change', event => {
    //     if (event.matches) {
    //       console.log("DARK");
    //       setTheme("dark")
    //       localStorage.setItem('Theme', "dark")
    //     } else {
    //       console.log("LIGHT");
    //       setTheme("light")
    //       localStorage.setItem('Theme', "light")
    //     }
    //   })
  }, [])

  function toogleTheme() {
    if (theme === "light") {
      setTheme("dark")
      localStorage.setItem('Theme', "dark")

    }
    if (theme === "dark") {
      setTheme("light")
      localStorage.setItem('Theme', "light")

    }

  }




  return <themeContext.Provider value={{ theme, toogleTheme }}>
    {children}
  </themeContext.Provider>
}