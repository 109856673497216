import React from 'react'
import "./styles.css"
export default function BoxInfo({theme, color, icon, title, info, type, medida, func, pointer}) {
    var simbolo = type?type:""
    var medidas = medida?medida:""
    return (
        <div onClick={func} className={theme==="light"? `bg-${color} ${pointer?"cursor-pointer":null} datos--tarjeta`:`bg-secondary ${pointer?"cursor-pointer":null} datos--tarjeta`}>
        <span className={`boxIconDatos bg-${color}`}><i className={icon}></i></span>
        <div className="boxInfoDatos">
            <strong className="text-left">{title}</strong>
            <h4>{!isNaN(info)? simbolo+""+info+" "+medidas : <i className="fas fa-spinner fa-pulse"></i>}</h4>
        </div>
    </div>
    )
}
