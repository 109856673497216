// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie'
import { useHistory } from "react-router-dom";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
import React from 'react'

export default function Pie({dato, type, colors, interactions, link, toogleServices}) {
  let history = useHistory();

    // const data =[
    //     {
    //       "id": "DISCO",
    //       "label": "DISCO",
    //       "value": 450,
    //     //   "color": "hsl(319, 70%, 50%)"
    //     },
    //   ]
      const margin = { top: 30, right: 200, bottom: 30, left: 30 };

      const styles = {
        root: {
          fontFamily: "consolas, sans-serif",
          textAlign: "center",
          position: "relative",
          width: "100%",
          height: 300
        },
        overlay: {
          position: "absolute",
          top: 0,
          right: margin.right,
          bottom: 0,
          left: margin.left,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 96,
          color: "#FFFFFF",
          // background: "#FFFFFF33",
          textAlign: "center",
          // This is important to preserve the chart interactivity
          pointerEvents: "none"
        },
        totalLabel: {
          fontSize: 8
        }
      };
    return (
        <div style={styles.root}>
          {/* {dato.length ==0? <i className="fas fa-spinner fa-pulse"></i>:null} */}
        <ResponsivePie
        onClick={(node, event)=> interactions === "actionsuc"? history.push(link+node.id):interactions === "actionserv"?toogleServices([node.id]):null}
        valueFormat={" >-"+type?type:""}
        data={dato}
        // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        margin={{ top: 20, right: 60, bottom: 40, left: 60 }}
        colors={{ scheme: colors? colors: "category10" }}
        // innerRadius={0.5}
        // padAngle={0.7}
        // cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        // borderColor={{ theme: 'background' }}
        borderColor={{ from: 'color', modifiers: [ [ 'brighter', '1' ] ] }}
        // arcLinkLabelsSkipAngle={10}
        // arcLinkLabelsTextColor="#333333"
        // arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
        // arcLinkLabelsThickness={2}
        // arcLinkLabelsColor={{ from: 'color' }}
        // arcLabelsSkipAngle={10}
        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        arcLinkLabelsTextOffset={5}
        arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
        arcLinkLabelsOffset={4}
        arcLinkLabelsDiagonalLength={4}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        arcLabelsSkipAngle={10}
        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        // arcLabel={function(e){return e.id+" ("+e.value+")"}}
        arcLabelsRadiusOffset={0.6}
        // arcLabelsTextColor={{ theme: 'background' }}
        arcLabelsTextColor="#ffffff"
        // arcLabel={function(e){return e.id+" ("+e.value+")"}}
        theme={{
          fontSize:"0.6rem",
          tooltip: {
            fontSize:"0.8rem",
            color: 'black',
          },
        }}
        
        // legends={[
          //     {
            //         anchor: 'bottom',
            //         direction: 'row',
            //         justify: false,
            //         translateX: 0,
            //         translateY: 56,
            //         itemsSpacing: 0,
            //         itemWidth: 100,
            //         itemHeight: 18,
            //         itemTextColor: '#999',
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 1,
            //         symbolSize: 18,
            //         symbolShape: 'circle',
            //         effects: [
              //             {
                //                 on: 'hover',
                //                 style: {
                  //                     itemTextColor: '#000'
                  //                 }
                  //             }
                  //         ]
                  //     }
                  // ]}
                  />
               
                  </div>
                  )
                }
                