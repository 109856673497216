import './App.css';
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from './context/themeContext';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import config from "./config"
import Admin from './layout/Admin';
import Login from './views/login/Login';
import { ServiceProvider } from './context/serviceContext';
import { SidebarProvider } from './context/sidebarContext';
import {isIOS} from "./utils/utils";

function App() {
  const [logger, setLogger] = useState(localStorage.getItem("token") ? true : false)

  async function validateUser() {
    try {
      fetch(config.url + ":" + config.port + "/users/validate", {
        method: "POST",
        headers: {
          "username": localStorage.getItem("user"),
          "Authorization": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }).then(response => response.json()).then(resp => {
        if (resp.validate === true) {
          setLogger(true)
        } else if (resp.validate === false) {
          setLogger(false)
          localStorage.removeItem("token")
          localStorage.removeItem("user")
        }
      }).catch(e => { alert("Internal Error 500, contacte a soporte") })
    }
    catch (e) {
      alert("servidor inaccessible")
    }
  }

  async function showNotification() {
    //Obtener la registración del service worker
    const registration = await navigator.serviceWorker.getRegistration()
    // console.log(registration);
    if (!registration) {
      return console.error("REGISTRATION VACIO");
    } else {
      registration.showNotification("Esto es una alerta de prueba", {
        body: 'Aqui va el contenido',
        vibrate: [300, 100, 400],
        img: '/favicon.png',
        data: { doge: { wow: 'Datos importantes de la notificacion' } }
      })
    }
  }

  useEffect(() => {
    validateUser()
    try {
      if (!isIOS()) {
        if (Notification.permission === 'default') {
          Notification.requestPermission().then(() => console.log("aceptado"))
        }
      }


    } catch (error) {
      console.log(error)
    }
    //Verificar si el browser soporta notificaciones
    // if (!('Notification' in window) || !('serviceWorker' in navigator)) {
    //   return alert('Tu browser no soporta notificaciones')
    // }
    //Si las notificaciones se encuentran por defecto
    // if (Notification.permission === 'default') {
    //   Notification.requestPermission()
    // }

    //Si las notificaciones se encuentran bloqueadas
    // if (Notification.permission === 'blocked') {
    // return alert('Bloqueaste las notificaciones')
    // }

    //Si las notificaciones tienen algún permiso que no sea habilitado
    // if (Notification.permission === 'granted') {
    // return alert('habilitadas las notificaciones')

    // }
  }, [])

  return (
    <ThemeProvider value={[]}>
      <SidebarProvider value={[]}>
        <ServiceProvider value={[]}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login"><Login logger={logger} setLogger={setLogger}></Login></Route>
              <Route path="/"><Admin logger={logger} setLogger={setLogger} /></Route>
            </Switch>
            {logger === false ? <Redirect to="/login" /> : null}
          </BrowserRouter>
        </ServiceProvider>
      </SidebarProvider>
    </ThemeProvider>

  );
}

export default App;
