
let config = {

    // url: "http://192.168.87.38",
    url: "https://dispensadores.aconline.com.uy",
    
    // port: "8877"
    port: "443"
}




export default config