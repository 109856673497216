import React from 'react';
import "./styles.css";
export default function DispensadorBox({ theme, remanining, nombre, sucursal, etiquetas, id, capacity, location, service, func }) {
    function porcent(valor){
        return ((valor*100)/capacity).toFixed();
    }

    return (
        <div className="box-dispense cursor-pointer" onClick={func}>
            <div className={`BoxInterto BoxInterto-${theme}`}>
                <div className="nombre d-block">{service.toUpperCase() + " - " + sucursal}</div>
                <small className="sucursal d-block">{location+" ("+id+")"} </small>
                <div className="canillas-box">
                    {remanining.map((dato, index)=>{
                        return (
                            <div key={index} className="canilla-individual">
                        <div className="remaniningCanilla" style={{ height: porcent(dato.value/1000) + "%", backgroundColor: porcent(dato.value/1000) < 21 ? "red" : porcent(dato.value/1000) < 38 ? "orange" : porcent(dato.value/1000) < 50 ? "yellow" : "#01d401" }}>
                            <label className="porcent-canilla-label">{porcent(dato.value/1000)+" %"}</label>
                        </div>
                    </div>
                        )
                    })
                    }
                </div>
                <div className="info"><small>Etiquetas:</small> <strong className={etiquetas<61? "text-danger": null}>{etiquetas}</strong></div>
            </div>
        </div>
    )
}

// Asi tenemos que llamar al componente y cargarlo de datos
//  <DispensadorBox etiquetas={25} theme={tDispensadorBoxheme} nombre={"Del Frente"} sucursal={"sucursal 27"} remanining1={0} remanining2={99} remanining3={50}></DispensadorBox>
