import React, { useEffect } from 'react'
import Header from '../container/header/Header'
import Sidebar from '../container/sidebar/Sidebar'
import "./styles.css"
import { useThemeContext } from '../context/themeContext'
import { useServiceContext } from '../context/serviceContext'
import { useSidebarContext } from '../context/sidebarContext'
import { Switch, Route, Redirect } from "react-router-dom";
import Location from '../views/Location/Location'
import Home from '../views/home/Home.js'
import Machines from '../views/machines/Machines'
import Alarms from '../views/alarms/Alarms'
import PageNotFound from '../views/pageNotFound/PageNotFound'
import config from '../config'

export default function Admin({ logger, setLogger }) {
    const { theme } = useThemeContext()
    const { services, originServices, toogleServices } = useServiceContext()
    const { stateSidebar, toogleSidebar } = useSidebarContext()

    function urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, "+")
            .replace(/_/g, "/");
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const publicVapidKey = 'BPbzrcBmWa3FXztyMRzcAuwiM0xEvad36ntj-zaPCtTJKfqH831S6-zhPDyqTW8_OU5Bsllfd6yBZ74_BFgbf9M';

    async function send() {
        //register service worker
        const register = await navigator.serviceWorker.register("/service-worker.js")
        console.log("registrando")
        console.log("registrando", register)

        //register push
        const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,

            //public vapid key
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });
        console.log("suscripotion:");
        console.log(subscription);
        // localStorage.setItem("push", subscription)
        //Send push notification
        await fetch(config.url + ":" + config.port + "/subscribe", {
            method: "POST",
            body: JSON.stringify(subscription),
            headers: {
                "username": localStorage.getItem("user"),
                "Authorization": localStorage.getItem("token"),
                "content-type": "application/json"
            }
        });
    }

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            send()
        }
    }, [])

    return (
        <>
            {services.length > 0 ?
                <div className={`admin-${theme}`}>
                    {!localStorage.getItem("service") ? <Redirect from="/*" to="/" /> : null}
                    <div onClick={() => toogleSidebar()} className={`buttonOpenSide d-block d-sm-none buttonOpenSide-${theme}`}><i className={stateSidebar === "halfopen" ? "fas fa-ellipsis-v" : "fas fa-angle-double-left"} aria-hidden="true"></i></div>
                    <Header theme={theme} logger={logger} setLogger={setLogger} services={services} toogleServices={toogleServices} />
                    <Sidebar theme={theme} services={services} stateSidebar={stateSidebar} />
                    <div className={`main main-${theme} main-${stateSidebar}`}>
                        <Switch>
                            <Route exact path="/">
                                <Home toogleServices={toogleServices} services={services} originServices={originServices} theme={theme} />
                            </Route>
                            <Route exact path="/location/:sucursal/">
                                <Location services={services} theme={theme} />
                            </Route>
                            <Route exact path="/machine/:id">
                                <Machines services={services} theme={theme} />
                            </Route>
                            <Route exact path="/alarms">
                                <Alarms services={services} theme={theme} />
                            </Route>
                            <Route><PageNotFound /></Route>
                        </Switch>

                    </div>
                </div>
                : null
            }
        </>
    )
}
