import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from "react-router-dom";
import { DateRange } from 'react-date-range';
import { getDetailTable, getFullData, getLiterDispensed, getHistoricsTrend, getBillingDispensed, getAllDevices, getHistoricsBar } from '../../services/Services'
import { subDays } from "date-fns";
import "./styles.css"
import { parseoMachinePie, parseoMachines } from '../../utils/utils';
import DispensadorBox from '../../components/DispensadorBox/DispensadorBox';
import ExportToExcel from '../../components/ExportToExcel/ExportToExcel';
import Bar from '../../components/nivo/bar/Bar';
import Pie from '../../components/nivo/pie/Pie';
import Line from '../../components/nivo/Line/Line';

// const tableDetailMl = [
//     {
//         "name": "bela",
//         "data": [
//             {
//                 "service": "bela",
//                 "store": "Sucursal12",
//                 "machine": "bela0001",
//                 "device": "d01",
//                 "product": "Suavizante para lavarropas",
//                 "billing": 5841,
//                 "option":500,
//                 "transactions": 99,
//                 "quantity": 99000
//             },
//             {
//                 "service": "bela",
//                 "option":1000,
//                 "store": "Sucursal12",
//                 "machine": "bela0001",
//                 "device": "d01",
//                 "product": "Suavizante para lavarropas",
//                 "billing": 11771,
//                 "transactions": 79,
//                 "quantity": 237000
//             },
//             {
//                 "service": "bela",
//                 "store": "Sucursal12",
//                 "option":3000,
//                 "machine": "bela0001",
//                 "device": "d01",
//                 "product": "Suavizante para lavarropas",
//                 "billing": 2418,
//                 "transactions": 62,
//                 "quantity": 31000
//             },
//             {
//                 "service": "bela",
//                 "option":500,
//                 "store": "Sucursal12",
//                 "machine": "bela0001",
//                 "device": "d02",
//                 "product": "Jabón para lavarropas",
//                 "billing": 7189,
//                 "transactions": 91,
//                 "quantity": 91000
//             },
//             {
//                 "service": "bela",
//                 "store": "Sucursal12",
//                 "option":1000,
//                 "machine": "bela0001",
//                 "device": "d02",
//                 "product": "Jabón para lavarropas",
//                 "billing": 31840,
//                 "transactions": 160,
//                 "quantity": 480000
//             },
//             {
//                 "service": "bela",
//                 "store": "Sucursal12",
//                 "option":3000,
//                 "machine": "bela0001",
//                 "device": "d02",
//                 "product": "Jabón para lavarropas",
//                 "billing": 2597,
//                 "transactions": 53,
//                 "quantity": 26500
//             },
//             {
//                 "service": "bela",
//                 "option":500,
//                 "store": "Sucursal12",
//                 "machine": "bela0001",
//                 "device": "d03",
//                 "product": "Detergente en Gel",
//                 "billing": 3185,
//                 "transactions": 65,
//                 "quantity": 65000
//             },
//             {
//                 "service": "bela",
//                 "store": "Sucursal12",
//                 "option":1000,
//                 "machine": "bela0001",
//                 "device": "d03",
//                 "product": "Detergente en Gel",
//                 "billing": 7740,
//                 "transactions": 60,
//                 "quantity": 180000
//             },
//             {
//                 "service": "bela",
//                 "option":3000,
//                 "store": "Sucursal12",
//                 "machine": "bela0001",
//                 "device": "d03",
//                 "product": "Detergente en Gel",
//                 "billing": 899,
//                 "transactions": 31,
//                 "quantity": 15500
//             }
//         ]
//     }
// ]

export default function Machines({ services, theme }) {
    let history = useHistory();
    let machine = useParams()
    let query = useQuery();
    let sucursal = query.get("st")
    let machineID = machine.id || undefined

    //para las maquinas en tiempo real
    const [machines, setMachines] = useState([])

    //para las graficas
    const [datosPIElitros, setDatosPIElitros] = useState([])
    const [datosPIEfacturacion, setDatosPIEfacturacion] = useState([])
    const [datosLineLitros, setDatosLineLitros] = useState()
    const [datosBarLitros, setDatosBarLitros] = useState()
    const [datosBarfacturacion, setDatosBarfacturacion] = useState()

    //para el excelFile
    const [dataExcel, setDataexcel] = useState("")

    // para tabla de detalles de opciones de ML
    const [tableDetailMl, setTableDetailMl] = useState([])

    // modos de las graficas groupMode
    const [barModoLitros, setBarModoLitros] = useState("stacked")
    const [barModoFacturacion, setBarModoFacturacion] = useState("grouped")


    //DataPicker
    const [dateSelected, setDateSelected] = useState(0)
    const [showingDatePicker, setShowingDatePicker] = useState(false)
    const [range, setRange] = useState(
        [{
            startDate: subDays(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())), 0),
            endDate: new Date(),
            key: 'selection'
        }]
    );

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function datePickerHandler(ranges) {
        setRange(
            [{
                startDate: ranges.startDate,
                endDate: new Date(ranges.endDate.getTime() + 86399000),
                key: 'selection'
            }]
        )
        if (ranges.startDate !== ranges.endDate) {
            const startDate = ranges.startDate.getTime() / 1000
            const endDate = (ranges.endDate.getTime() + 86399000) / 1000
            contarDatos(startDate, endDate)
            //esconde el datapicker
            setShowingDatePicker(false)
        }
    }
    function datePicker() {
        if (!showingDatePicker) {
            return null
        } else {
            return (
                <div className="calendar">
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => datePickerHandler(item.selection)}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        style={{ width: "100%" }}
                    />
                </div>
            )
        }
    }

    function btnTiempoPredefinido(btn) {
        setDateSelected(btn)
        setRange([{ startDate: subDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), btn), endDate: new Date(), key: 'selection' }])
        contarDatos((subDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), btn).getTime() / 1000), new Date().getTime() / 1000)
        setShowingDatePicker(false)
    }

    function contarDatos(from, to) {
        let service = services.length === 1 ? services : undefined
        let timezone = new Date().getTimezoneOffset()
        setTableDetailMl()
        setDataexcel()
        setDatosPIElitros([])
        setDatosPIEfacturacion([])
        getHistoricsBar(from, to, service, "quantity", sucursal, machineID).then(resp => { setDatosBarLitros(resp.trend) })
        getHistoricsBar(from, to, service, "amount", sucursal, machineID).then(resp => setDatosBarfacturacion(resp.trend))
        getHistoricsTrend(from, to, service, "quantity", sucursal, machineID).then(resp => setDatosLineLitros(resp.trend))
        getLiterDispensed(from, to, timezone, service, sucursal, machineID).then(resp => {
            // console.log(resp.dispensed);
            setDatosPIElitros(parseoMachinePie(resp.dispensed, 1000))
        })
        getBillingDispensed(from, to, timezone, service, sucursal, machineID).then(resp => {
            // console.log(parseoMachinePie(resp.billing,1));
            setDatosPIEfacturacion(parseoMachinePie(resp.billing, 1))
        })
        getFullData(from, to, service, sucursal, machineID).then(resp => setDataexcel(resp))
        getDetailTable(from, to, service, sucursal, machineID).then(resp => setTableDetailMl(resp))

    }

    useEffect(() => {
        // getMachinesLight(services).then((machine) => console.log(machine.data))
        if (services.length === 1) {
            getAllDevices(services, sucursal, machineID).then(resp => {
                // console.log(parseoMachines(resp));
                setMachines(parseoMachines(resp))
            })
            contarDatos(range[0].startDate.getTime() / 1000, range[0].endDate.getTime() / 1000)
        }
    }, [services])
    return (
        <>
            <div className="container-fluid">
                <div onClick={() => history.goBack()} className={`cursor-pointer text-start text-info w-100 text-${theme === "dark" ? "white" : "muted"} `}><i className="fas fa-backward "></i><span > volver</span></div>
            </div>

            <div className="container-fluid">
                {/* <button onClick={()=>history.goBack()}>ATRAS</button> */}
                <h4>Maquina</h4>
                <div className="mt-3 mb-3 text-center">
                    <div className="btn-group-sm" role="group" aria-label="...">
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 30 ? "active" : null}`} onClick={() => btnTiempoPredefinido(30)}>Mes</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 6 ? "active" : null}`} onClick={() => btnTiempoPredefinido(6)}>Semana</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 0 ? "active" : null}`} onClick={() => btnTiempoPredefinido(0)}>Dia</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 100 ? "active" : null}`} onClick={() => { setShowingDatePicker(!showingDatePicker); setDateSelected(100) }}>Rango</button>
                        <ExportToExcel data={dataExcel} ></ExportToExcel>
                    </div>
                    {datePicker()}
                </div>
                <section className="datos mt-3 ">
                    {/* {machines.filter(e => e.id === machineID && e.service === services.toString()).map((machine) => { */}
                        {machines.map((machine) => {
                        return <DispensadorBox key={machine.id} etiquetas={machine.labels} theme={theme} id={machine.id} capacity={175} nombre={machine.name} sucursal={machine.sucursal} remanining={machine.remaining} location={machine.location} service={machine.service}></DispensadorBox>
                    })
                    }
                </section >
                <section className="row">
                    <div className="col-md-6 col-sm-6 col-lg-4 mb-4 col-xxl-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <h4>Litros</h4>
                            <Pie dato={datosPIElitros}></Pie>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mb-4 col-xxl-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <h4>Ventas</h4>
                            <Pie dato={datosPIEfacturacion}></Pie>
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-lg-4 col-xxl-4 mb-4">
                        <div className={`border h-100 p-2 box-Item box-Item-${theme}`}>
                            <div className="table-responsive tableHeight21">
                                <h4>Detalles de transacciones</h4>
                                <table className="table text-nowrap table-hover table-sm" style={{ fontSize: "0.7rem" }}>
                                    <thead>
                                        <tr className="bg-success text-white">
                                            <th scope="col">Producto</th>
                                            <th scope="col">Opción</th>
                                            {/* <th scope="col">Ventas</th> */}
                                            <th scope="col">Total</th>
                                            <th scope="col">Facturación</th>
                                        </tr>
                                    </thead>
                                    <tbody className={theme === "light" ? null : "text-white"}>
                                        {tableDetailMl ?
                                            tableDetailMl.map((item, index) => {
                                                return item.data.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{value.product}</td>
                                                            <td>{value.option / 1000 + " L"}</td>
                                                            {/* <td>{value.transactions}</td> */}
                                                            <td>{value.quantity / 1000 + " L"}</td>
                                                            <td>{"$ " + value.billing}</td>
                                                        </tr>
                                                    )
                                                })
                                            }) :
                                            <tr key={"loadingkey"}>
                                                <td><i className="fas fa-spinner fa-spin"/></td>
                                                <td><i className="fas fa-spinner fa-spin"/></td>
                                                <td><i className="fas fa-spinner fa-spin"/></td>
                                                <td><i className="fas fa-spinner fa-spin"/></td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12  col-lg-12 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <h4>Tendencia Litros</h4>
                            <Line theme={theme} dato={datosLineLitros} type="quantity" />
                        </div>
                    </div>
                    <div className="col-md-12  col-lg-12 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <button type="button" className="btn btn-outline-secondary btn-sm float-end d-none d-sm-block" onClick={() => setBarModoLitros(barModoLitros === "stacked" ? "grouped" : "stacked")}>{barModoLitros === "stacked" ? "Agrupar" : "Apilar"}</button>
                            <h4>Litros</h4>
                            <Bar groupMode={barModoLitros} theme={theme} dato={datosBarLitros} type="quantity"></Bar>
                        </div>
                    </div>
                    <div className="col-md-12  col-lg-12 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <button type="button" className="btn btn-outline-secondary btn-sm float-end d-none d-sm-block" onClick={() => setBarModoFacturacion(barModoFacturacion === "stacked" ? "grouped" : "stacked")}>{barModoFacturacion === "stacked" ? "Agrupar" : "Apilar"}</button>
                            <h4>Ventas</h4>
                            <Bar groupMode={barModoFacturacion} theme={theme} dato={datosBarfacturacion} type="amount"></Bar>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
