import React from 'react'
import { ResponsiveLine } from '@nivo/line'


export default function Line({dato, theme, type}) {
  // console.log(dato);
    // const data= [
    //     {
    //       "id": "Disco",
    //       "data": [
    //         { x: new Date('2021-09-08 17:06:45.200'), y: 14 },
    //         { x: new Date('2021-09-09 17:07:45.200'), y: 55 },
    //         { x: new Date('2021-09-10 17:08:45.200'), y: 22 },
    //         { x: new Date('2021-09-10 19:06:45.200'), y: 85 },
    //         { x: new Date('2021-09-11 20:06:45.200'), y: 5 },
    //       ]
    //     },
    //     {
    //         "id": "Devoto",
    //         "data": [
    //             { x: new Date('2021-09-08 17:06:45.200'), y: 84 },
    //             { x: new Date('2021-09-09 17:07:45.200'), y: 45 },
    //             { x: new Date('2021-09-10 17:08:45.200'), y: 82 },
    //             { x: new Date('2021-09-10 19:06:45.200'), y: 55 },
    //             { x: new Date('2021-09-11 20:06:45.200'), y: 65 },
    //         ]
    //       },
    //       {
    //         "id": "Bela",
    //         "data": [
    //             { x: new Date('2021-09-08 17:06:45.200'), y: 84 },
    //             { x: new Date('2021-09-18 17:06:45.200'), y: 84 },
    //             { x: new Date('2021-09-28 17:06:45.200'), y: 84 },
    //             { x: new Date('2021-10-28 17:06:45.200'), y: 84 },
    //         ]
    //     }
    //   ]

      const styles = {
        root: {
          fontFamily: "consolas, sans-serif",
          textAlign: "center",
          position: "relative",
          width: "100%",
          height: 300
        },
      };

      function tipoDato(tipo){
        if (tipo ==="quantity") {
          return ( value =>`${Number(value) /1000} lts`)
        }else if (tipo ==="amount"){
         return value => `${Number(value)} $`
        }
      }

    return (
        <div  style={styles.root}>
          {dato?
            <ResponsiveLine
            gridYValues={"Date"}
            data={dato}
            margin={{ top: 20, right: 70, bottom: 50, left: 35 }}
            // xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"
            xFormat="time:%d-%m-%Y %H:%M"
            xScale={{ format: "%Y-%m-%dT%H:%M:%S.%L%Z", type: "time"}}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            // yFormat=" >-.2f"
            yFormat={tipoDato(type)}
            //   {type ==="quantity"?
            //   value =>
            //   `${Number(value) /1000} lts`
            // :   
            // value =>
            // `${Number(value)} $`  
            // }
            
            curve="linear"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              format: "%b %d",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'fecha',
              legendOffset: 36,
              legendPosition: 'middle',
              // tickValues: 'every weeks',
            }}
            axisLeft={{
              format: type === "quantity" ? value => `${Number(value) / 1000}` : "",
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              // legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            // axisBottom={{
              //     tickValues: "every 1 second",
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     format: "%S.%L",
        //     legend: "Time",
        //     legendOffset: 36,
        //     legendPosition: "middle"
        //   }}
        colors={{ scheme: 'category10' }}
        pointSize={5}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        pointLabelYOffset={-12}
        areaOpacity={0.15}
        useMesh={true}
        theme={{
          textColor: theme=== "light"?"black":"white",
          axis: { ticks: { text: { fontSize: 8 } } },
          fontSize:"0.6rem",
          tooltip: {
            fontSize:"0.8rem",
            color: 'black',
          },
        }}
        // theme={{
          //     axis: { ticks: { text: { fontSize: 8 } } },
          //     // grid: { line: { stroke: '#ddd', strokeDasharray: '1 2' } },
          // }}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 85,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          />
        :<i className="fas fa-spinner fa-pulse"></i>}
          </div>
          )
        }
        