import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from "react-router-dom";
import { DateRange } from 'react-date-range';
import { getFullData, getLiterDispensed, getCountDevices, getCountAlarms, getBillingDispensed, getAllDevices, getHistoricsBar } from '../../services/Services'
import { subDays } from "date-fns";
import BoxInfo from '../../components/boxInfo/BoxInfo';
import "./styles.css"
import { parseoMachinePie, parseoMachines, parseoPie } from '../../utils/utils';
import DispensadorBox from '../../components/DispensadorBox/DispensadorBox';
import Bar from '../../components/nivo/bar/Bar';
import ExportToExcel from '../../components/ExportToExcel/ExportToExcel'


export default function Location({ services, theme }) {
    let history = useHistory();
    let sucursal = useParams()
    let query = useQuery();
    let prettySucursal = query.get("pn")
    let sucursalID = sucursal.sucursal || undefined
    //para los box de tiempo real
    const [dispositivosCount, setDispositivosCount] = useState()
    const [alarmsCount, setAlarmsCount] = useState()
    const [dispensedCount, setDispensedCount] = useState()
    const [billingCount, setbillingCount] = useState()

    //para las maquinas en tiempo real
    const [machines, setMachines] = useState([])

    //para el excelFile
    const [dataExcel, setDataexcel] = useState("")

    //para las graficas
    const [datosBarLitros, setDatosBarLitros] = useState()
    const [datosBarfacturacion, setDatosBarfacturacion] = useState()

    // modos de las graficas groupMode
    const [barModoLitros, setBarModoLitros] = useState("stacked")
    const [barModoFacturacion, setBarModoFacturacion] = useState("stacked")


    //DataPicker
    const [dateSelected, setDateSelected] = useState(0)
    const [showingDatePicker, setShowingDatePicker] = useState(false)
    const [range, setRange] = useState(
        [{
            startDate: subDays(new Date(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate())), 0),
            endDate: new Date(),
            key: 'selection'
        }]
    );

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function datePickerHandler(ranges) {
        setRange(
            [{
                startDate: ranges.startDate,
                endDate: new Date(ranges.endDate.getTime() + 86399000),
                key: 'selection'
            }]
        )
        if (ranges.startDate !== ranges.endDate) {
            const startDate = ranges.startDate.getTime() / 1000
            const endDate = (ranges.endDate.getTime() + 86399000) / 1000
            contarDatos(startDate, endDate)
            //esconde el datapicker
            setShowingDatePicker(false)
        }
    }
    function datePicker() {
        if (!showingDatePicker) {
            return null
        } else {
            return (
                <div className="calendar">
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => datePickerHandler(item.selection)}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        style={{ width: "100%" }}
                    />
                </div>
            )
        }
    }
    function btnTiempoPredefinido(btn) {
        setDateSelected(btn)
        setRange([{ startDate: subDays(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()), btn), endDate: new Date(), key: 'selection' }])
        contarDatos((subDays(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()), btn).getTime() / 1000), new Date().getTime() / 1000)
        setShowingDatePicker(false)
    }

    function contarDatos(from, to) {
        let service = services.length === 1 ? services : undefined
        let timezone = new Date().getTimezoneOffset()
        setDataexcel()

        setDispensedCount()
        setbillingCount()
        getHistoricsBar(from, to, service, "quantity", sucursalID).then(resp => { setDatosBarLitros(resp.trend)})
        getHistoricsBar(from, to, service, "amount", sucursalID).then(resp => setDatosBarfacturacion(resp.trend))
        getLiterDispensed(from, to, timezone, service, sucursalID).then(resp => {
            // console.log(resp.dispensed);
            setDispensedCount(resp.dispensed.reduce((t, n) => t + n["value"], 0))
        })
        getBillingDispensed(from, to, timezone, service, sucursalID).then(resp => {
            // console.log(parseoMachinePie(resp.billing,1));
            // console.log(resp.billing);
            setbillingCount(resp.billing.reduce((t, n) => t + n["value"], 0))
        })
        getFullData(from, to, service, sucursalID).then(resp=>setDataexcel(resp))
    }

    useEffect(() => {
        if (services.length === 1) {
            getAllDevices(services, sucursalID).then(resp => {
                setMachines(parseoMachines(resp))
                // console.log(parseoMachines(resp))
            })
            getCountAlarms(services, sucursalID).then(resp => setAlarmsCount(resp.alarms))
            getCountDevices(services, sucursalID).then(resp => setDispositivosCount(resp.devices));
            contarDatos(range[0].startDate.getTime() / 1000, range[0].endDate.getTime() / 1000)
        }
    }, [services])

    return (
        <>
            <div className="container-fluid">
                <div onClick={() => history.goBack()} className={`cursor-pointer text-start text-info w-100 text-${theme==="dark"?"white":"muted"} `}><i className="fas fa-backward "></i><span > volver</span></div>
            </div>
            <div className="location container-fluid">

                <section className="datos ">
                    <BoxInfo
                        theme={theme} color="info" title="dispositivos" info={dispositivosCount} icon="fas fa-project-diagram"
                    />
                    <BoxInfo
                        theme={theme} color="danger" title="Alarmas Activas" info={alarmsCount} icon="fas fa-bell"
                    />
                    <BoxInfo
                        theme={theme} color="warning" title="Litros dispensados" info={dispensedCount / 1000} medida={"Lts"} icon="fas fa-tint"
                    />
                    <BoxInfo
                        theme={theme} color="success" title="Facturación" type={"$"} info={billingCount} icon="fas fa-dollar-sign"
                    />
                </section >
                <hr />
                <section>

                    <h4>SUCURSAL</h4>
                </section>
                <div className="mt-3 mb-3 text-center">
                    <div className="btn-group-sm" role="group" aria-label="...">
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 30 ? "active" : null}`} onClick={() => btnTiempoPredefinido(30)}>Mes</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 6 ? "active" : null}`} onClick={() => btnTiempoPredefinido(6)}>Semana</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 0 ? "active" : null}`} onClick={() => btnTiempoPredefinido(0)}>Dia</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 100 ? "active" : null}`} onClick={() => { setShowingDatePicker(!showingDatePicker); setDateSelected(100) }}>Rango</button>
                <ExportToExcel data={dataExcel} ></ExportToExcel>
                    </div>
                    {datePicker()}
                </div>
                {/* <h6><strong>{prettySucursal}</strong></h6> */}
                <section className="datos mt-3 ">
                    {machines.map((machine, h) =>{
                        return <DispensadorBox func={() => history.push("/machine/" + machine.id + "?st=" + sucursalID)} key={machine.id} etiquetas={machine.labels} theme={theme} id={machine.id} capacity={175} nombre={machine.name} sucursal={machine.sucursal} remanining={machine.remaining} location={machine.location} service={machine.service}></DispensadorBox>
                    })}
                    
                </section >
                <section className="row">
                    <div className="col-md-12  col-lg-12 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <button type="button" className="btn btn-outline-secondary btn-sm float-end d-none d-sm-block" onClick={() => setBarModoLitros(barModoLitros === "stacked" ? "grouped" : "stacked")}>{barModoLitros === "stacked" ? "Agrupar" : "Apilar"}</button>
                            <h4>Litros</h4>
                            <Bar groupMode={barModoLitros} theme={theme} dato={datosBarLitros} type="quantity"></Bar>
                        </div>
                    </div>

                    <div className="col-md-12  col-lg-12 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <button type="button" className="btn btn-outline-secondary btn-sm float-end d-none d-sm-block" onClick={() => setBarModoFacturacion(barModoFacturacion === "stacked" ? "grouped" : "stacked")}>{barModoFacturacion === "stacked" ? "Agrupar" : "Apilar"}</button>
                            <h4>Ventas</h4>
                            <Bar groupMode={barModoFacturacion} theme={theme} dato={datosBarfacturacion} type="amount"></Bar>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}