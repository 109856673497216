import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const dataSet1 = [
//     {
//         name: "farmashop",
//         dato: [
//             {
//                 Sucursal: "suc 27",
//                 dispositivo: "farm0001",
//                 producto: 'jabon',
//                 cantidad: "25",
//                 precio: "355",
//                 fecha: "25/11/25 00:05"
//             },
//           ]
//     },
//     {
//         name: "bela",
//         dato: [
//             {
//                 Sucursal: "suc 55",
//                 dispositivo: "bela0001",
//                 producto: 'jabon',
//                 cantidad: "25",
//                 precio: "355",
//                 fecha: "25/11/25 00:05"
//             },
//         ]
//     }
// ]


export default function ExportToExcel({data}) {
    return (
        <>
            {data ?
                <ExcelFile element={<button type="button" className="ms-1 btn btn-success btn-sm border-white rounded-2">Exportar <i className="fas fa-file-excel"></i></button>}>
                    {data.map((service, h) => (
                        <ExcelSheet key={h+"key"}data={service.data} name={service.name}>
                            <ExcelColumn label="Sucursal" value={"sucursal"}/>
                            <ExcelColumn label="Dispositivo" value={"dispositivo"} />
                            <ExcelColumn label="Producto" value={"producto"} />
                            <ExcelColumn label="Mililitros" value={"cantidad"} />
                            <ExcelColumn label="Costo" value={(col) => col.cantidad === "0"? "0" : col.precio} />
                            <ExcelColumn label="Fecha" value={(col)=> new Date(col.fecha).toLocaleString()} />
                        </ExcelSheet>
                    ))}
                </ExcelFile>
                : <button type="button" className="ms-1 btn btn-success btn-sm border-white rounded-2" disabled>Exportar <i className="fas fa-circle-notch fa-spin"></i></button>
            }
        </>
    )
}

