import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

export default function Bar({ dato, theme, type, groupMode }) {
  // console.log(dato);
  // const data=[
  //     {
  //         "date": '09/08',
  //         "Disco": 190,
  //         "Devoto": 90,
  //         "Bela": 37,
  //       },
  // ]
  const styles = {
    root: {
      fontFamily: "consolas, sans-serif",
      textAlign: "center",
      position: "relative",
      width: "100%",
      height: 300
    },
  };

  function tipoDato(tipo) {
    if (tipo === "quantity") {
      return (value => `${Number(value) / 1000} lts`)
    } else if (tipo === "amount") {
      return value => `$${Number(value)}`
    }
  }
  return (
    <div style={styles.root}>
      {dato ?
        <ResponsiveBar
          data={dato.data}
          groupMode={groupMode || "stacked"}
          keys={dato.variables}
          indexBy="date"
          margin={{ top: 50, right: 53, bottom: 50, left: 35 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          valueFormat={tipoDato(type)}
          colors={{ scheme: 'category10' }}
          theme={{
            textColor: theme === "light" ? "black" : "white",
            axis: { ticks: { text: { fontSize: 8 } } },
            fontSize: "0.6rem",
            tooltip: {
              fontSize: "0.8rem",
              color: 'black',
            },
          }}
          labelTextColor="white"
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}

          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -30,
            // legend: 'country',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            format: type === "quantity" ? value => `${Number(value) / 1000}` : "",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'food',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 107,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 11,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 9,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
        : <i className="fas fa-spinner fa-pulse"></i>}
    </div>
  )
}
