
const routes = [
    {
        path: '/',
        icon: "fa fa-fw fa-home",
        text: "Inicio",
        child:{}
    },
    {
        path: '/location',
        icon: "fa fa-fw fa-location-arrow",
        text: "Ubicación",
        child:[]

    },
    {
        path: '/alarms',
        icon: "fa fa-fw fa-bell",
        text: "Alarmas",
        child:{}
    },
]





export default routes
