import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { getLiterDispensed, getCountDevices, getCountAlarms, getBillingDispensed, getAllDevices, getHistoricsTrend, getHistoricsBar, getFullData } from '../../services/Services'
import { parseoPie, parseoTableLabels, parseoTableDispensers } from "../../utils/utils"
import { DateRange } from 'react-date-range';
import "./styles.css"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import BoxInfo from '../../components/boxInfo/BoxInfo'
import Pie from '../../components/nivo/pie/Pie';
import Line from '../../components/nivo/Line/Line';
import Bar from '../../components/nivo/bar/Bar';
import ExportToExcel from '../../components/ExportToExcel/ExportToExcel'
import { subDays } from "date-fns";

export default function Home({ theme, services, toogleServices, originServices }) {
    let history = useHistory();
    let location = useLocation();
    //para los box de tiempo real
    const [dispositivosCount, setDispositivosCount] = useState()
    const [alarmsCount, setAlarmsCount] = useState()
    const [dispensedCount, setDispensedCount] = useState()
    const [billingCount, setbillingCount] = useState()

    //para las graficas
    const [datosPIElitros, setDatosPIElitros] = useState([])
    const [datosPIEfacturacion, setDatosPIEfacturacion] = useState([])
    const [datosLineLitros, setDatosLineLitros] = useState()
    const [datosBarLitros, setDatosBarLitros] = useState()
    const [datosBarfacturacion, setDatosBarfacturacion] = useState()

    //para el excelFile
    const [dataExcel, setDataexcel] = useState("")

    // modos de las graficas groupMode
    const [barModoLitros, setBarModoLitros] = useState("stacked")
    const [barModoFacturacion, setBarModoFacturacion] = useState("stacked")

    //para las tablas
    const [tableLabels, setTableLabels] = useState([])
    const [tableDispensers, setTableDispensers] = useState([])

    //DataPicker
    const [dateSelected, setDateSelected] = useState(0)
    const [showingDatePicker, setShowingDatePicker] = useState(false)
    const [range, setRange] = useState(
        [{
            startDate: subDays(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())), 0),
            endDate: new Date(),
            key: 'selection'
        }]
    );
    function datePickerHandler(ranges) {
        setRange(
            [{
                startDate: ranges.startDate,
                endDate: new Date(ranges.endDate.getTime() + 86399000),
                key: 'selection'
            }]
        )
        if (ranges.startDate !== ranges.endDate) {
            const startDate = ranges.startDate.getTime() / 1000
            const endDate = (ranges.endDate.getTime() + 86399000) / 1000
            contarDatos(startDate, endDate)
            //esconde el datapicker
            setShowingDatePicker(false)
        }
    }
    function datePicker() {
        if (!showingDatePicker) {
            return null
        } else {
            return (
                <div className="calendar">
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => datePickerHandler(item.selection)}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        style={{ width: "100%" }}
                    />
                </div>
            )
        }
    }
    function btnTiempoPredefinido(btn) {
        setDateSelected(btn)
        setRange([{ startDate: subDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), btn), endDate: new Date(), key: 'selection' }])
        contarDatos((subDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), btn).getTime() / 1000), new Date().getTime() / 1000)
        setShowingDatePicker(false)
    }

    // console.log(subDays(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() ), 1))
    // console.log(new Date().toLocaleDateString())
    function contarDatos(from, to) {
        // console.log("FROM: "+from)
        // console.log("TO: "+to)
        let service = services.length === 1 ? services : undefined
        let timezone = new Date().getTimezoneOffset()
        setDataexcel()
        setDispensedCount()
        setbillingCount()
        setDatosPIElitros([])
        setDatosPIEfacturacion([])
        getHistoricsTrend(from, to, service, "quantity").then(resp => setDatosLineLitros(resp.trend))
        getHistoricsBar(from, to, service, "quantity").then(resp => { setDatosBarLitros(resp.trend) })
        getHistoricsBar(from, to, service, "amount").then(resp => setDatosBarfacturacion(resp.trend))
        getLiterDispensed(from, to, timezone, service).then(resp => {
            setDispensedCount(resp.dispensed.reduce((t, n) => t + n["value"], 0))
            setDatosPIElitros(parseoPie(resp.dispensed, 1000))
        })
        getBillingDispensed(from, to, timezone, service).then(resp => {
            setbillingCount(resp.billing.reduce((t, n) => t + n["value"], 0))
            setDatosPIEfacturacion(parseoPie(resp.billing, 1))
        })
        getFullData(from, to, service).then(resp => setDataexcel(resp))


    }
    useEffect(() => {
        if (services.length !== 0) {
            let service = services.length === 1 ? services : undefined
            getCountAlarms(service).then(resp => setAlarmsCount(resp.alarms))
            getCountDevices(service).then(resp => setDispositivosCount(resp.devices));
            getAllDevices(service).then(resp => {
                console.log(resp);
                setTableLabels(parseoTableLabels(resp))
                setTableDispensers(parseoTableDispensers(resp))
            })
            contarDatos(range[0].startDate.getTime() / 1000, range[0].endDate.getTime() / 1000)
        }

    }, [services])

    function servicesarmar() {
        if (services.length > 1) {
            const dato = services.map((service, index) => {
                return <img key={service + index + "img"} className="cursor-pointer m-1"
                    onClick={() => toogleServices([service])} style={{ width: "5rem" }}
                    alt={services}
                    src={`./img/service/${service}${theme === "dark" ? "-" + theme : ""}.png`}
                    onError={(e) => { e.target.src = `./img/service/${service}.png` }} />
            })
            return <div className={`w-100 mt-3`}>{dato}</div>
        } else if (services.length === 1) {
            return <div className={`cursor-pointer text-start text-info w-100 text-${theme === "dark" ? "white" : "muted"} `}><small onClick={() => toogleServices("clear")}> <i className="fas fa-sign-out-alt fa-rotate-180"></i> salir de {services}</small></div>
        }
    }



    return (
        <>
            {originServices.length > 1 ? <div className="container-fluid">{servicesarmar()}</div> : null}
            <div className="home container-fluid">
                <section className="datos ">
                    <BoxInfo
                        theme={theme} color="info" title="dispositivos" info={dispositivosCount} icon="fas fa-project-diagram"
                    />
                    <BoxInfo
                        pointer={true} func={() => history.push("/alarms")} theme={theme} color="danger" title="Alarmas Activas" info={alarmsCount} icon="fas fa-bell"
                    />
                    <BoxInfo
                        theme={theme} color="warning" title="Litros dispensados" info={dispensedCount / 1000} medida={"Lts"} icon="fas fa-tint"
                    />
                    <BoxInfo
                        theme={theme} color="success" title="Facturacion" type={"$"} info={billingCount} icon="fas fa-dollar-sign"
                    />

                </section >
                <hr />


                <div className="mt-3 mb-3 text-center">
                    <div className="btn-group-sm" role="group" aria-label="...">
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 30 ? "active" : null}`} onClick={() => btnTiempoPredefinido(30)}>Mes</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 6 ? "active" : null}`} onClick={() => btnTiempoPredefinido(6)}>Semana</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 0 ? "active" : null}`} onClick={() => btnTiempoPredefinido(0)}>Dia</button>
                        <button type="button" className={`btn btn-outline-info ${dateSelected === 100 ? "active" : null}`} onClick={() => { setShowingDatePicker(!showingDatePicker); setDateSelected(100) }}>Rango</button>
                        <ExportToExcel data={dataExcel} ></ExportToExcel>
                    </div>
                    {datePicker()}
                </div>

                <section className="row mb-3">
                    <div className="col-md-6 col-sm-6 col-lg-4 mb-4 col-xxl-3">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <h4>Litros</h4>
                            <Pie dato={datosPIElitros} toogleServices={toogleServices} interactions={services.length === 1 ? "actionsuc" : "actionserv"} link={"/location/"}></Pie>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-3 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <h4>Facturacion</h4>
                            <Pie dato={datosPIEfacturacion} toogleServices={toogleServices} type={"$"} interactions={services.length === 1 ? "actionsuc" : "actionserv"} link={"/location/"}></Pie>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-4 col-xxl-3 mb-4">
                        <div className={`border h-100 p-2 box-Item box-Item-${theme}`}>
                            <div className="table-responsive tableHeight21">
                                <h4>Proximos a Recargar</h4>
                                <table className="table text-nowrap table-hover table-sm" style={{ fontSize: "0.7rem" }}>
                                    <thead>
                                        <tr className="bg-danger text-white">
                                            <th scope="col">Servicio</th>
                                            <th scope="col">Sucursal</th>
                                            <th scope="col">Dispositivo</th>
                                            <th scope="col">Producto</th>
                                            <th scope="col">Litros</th>
                                        </tr>
                                    </thead>
                                    <tbody className={theme === "light" ? null : "text-white"}>
                                        {tableDispensers.map((e, index) => {
                                            return (
                                                <tr key={index} className={e.value < 60 ? "text-danger fw-bold" : null}>
                                                    <td>{e.service}</td>
                                                    <td>{e.store}</td>
                                                    <td>{e.name}</td>
                                                    <td>{e.dispositivo}</td>
                                                    <td>{e.value}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-4 col-xxl-3 mb-4">
                        <div className={`border h-100 p-2 box-Item box-Item-${theme}`}>
                            <div className="table-responsive tableHeight21">
                                <h4>Etiquetas</h4>
                                <table className="table text-nowrap table-hover table-sm" style={{ fontSize: "0.7rem" }}>
                                    <thead>
                                        <tr className="bg-primary text-white">
                                            <th scope="col">Servicio</th>
                                            <th scope="col">Sucursal</th>
                                            <th scope="col">Dispositivo</th>
                                            <th scope="col">Etiquetas</th>
                                        </tr>
                                    </thead>
                                    <tbody className={theme === "light" ? null : "text-white"}>
                                        {tableLabels.map((e, index) => {
                                            return (
                                                <tr key={index} className={e.value < 60 ? "text-danger fw-bold" : null}>
                                                    <td>{e.service}</td>
                                                    <td>{e.store}</td>
                                                    <td>{e.name}</td>
                                                    <td>{e.value}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12  col-lg-8 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <h4>Tendencia Litros</h4>
                            <Line theme={theme} dato={datosLineLitros} type="quantity" />
                        </div>
                    </div>

                    <div className="col-md-12  col-lg-12 col-xxl-6 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <button type="button" className="btn btn-outline-secondary btn-sm float-end" onClick={() => setBarModoLitros(barModoLitros === "stacked" ? "grouped" : "stacked")}>{barModoLitros === "stacked" ? "Agrupar" : "Apilar"}</button>
                            <h4>Litros diarios</h4>
                            <Bar groupMode={barModoLitros} theme={theme} dato={datosBarLitros} type="quantity"></Bar>
                        </div>
                    </div>

                    <div className="col-md-12  col-lg-12 mb-4">
                        <div className={`border p-2 box-Item box-Item-${theme}`}>
                            <button type="button" className="btn btn-outline-secondary btn-sm float-end" onClick={() => setBarModoFacturacion(barModoFacturacion === "stacked" ? "grouped" : "stacked")}>{barModoFacturacion === "stacked" ? "Agrupar" : "Apilar"}</button>
                            <h4>Ventas diarias</h4>
                            <Bar groupMode={barModoFacturacion} theme={theme} dato={datosBarfacturacion} type="amount"></Bar>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}
